@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
.title_form_ready {
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    text-align: center;
    font-weight: 600;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.title_landing {
    font-family: "Inter", serif;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
}

.wrapper_menu{
    height: 80vh;
}

.analytics__background {
    background-color: rgba(239, 240, 239, 1);
}


.analytic__row {
    display: flex;
    justify-content: space-between;
    padding: 3px;
}

.analytic__row__name {
    color: black;
}

.analytic__row__value {
    color: black;
    font-weight: bold;
}
