@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
.parent {
    display: flex;
    flex-direction: column;
}
.div1 {
    margin-bottom: 3vh;
}
.div2 {
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: 18px;
}
.div3 {
    margin-bottom: 2vh;
    font-size: 18px;
}
.div4 {
    margin-top: 3vh;
    margin-left: 3vw;
}


.title_landing {
    font-family: "Inter", serif;
    font-size: 30px;
    text-align: left;
    font-weight: 500;
}

@media screen and (max-width: 1024px) {
    .wrap_text {
        display: flex;
        flex-direction: column;
        margin-top: 2vh;
        padding: 0 3vw 0 3vw;
    }
}

@media screen and (min-width: 1024px) {
    .wrap_text {
        display: flex;
        flex-direction: row;
        margin-top: 2vh;
        padding: 0 3vw 0 3vw;
        
    }
    
    .center {
        margin-right: 2vw;
        margin-left: 2vw;
    }
}