.whatIsStatbim__Wrapper {
    display: flex;
    flex-direction: column;
}

.whatIsStatbim__HeaderName {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 36px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.whatIsStatbim__subName {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 28px;
    margin-left: auto;
    margin-right: auto;
}

.whatIsStatbim__HeaderText {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 19px;
}

.whatIsStatbim__Diagram {
    width: 80vw;
    max-width: 1550px;
    margin-top: 4vh;
    margin-left: auto;
    margin-right: auto;
}

.whatIsStatbim__Diagram__General-icon-wrapper {
    display: flex;
    height: 100px;
    margin-top: auto;
    margin-bottom: auto;
    width: 300px;
}

.whatIsStatbim__Diagram__General-icon-wrapper-text {
    max-height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    width: 215px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 22px;
}

.whatIsStatbim__Diagram__General__BlackCircle-photo {
    width: 50px;
    height: 50px;
    margin: auto;
}

.whatIsStatbim__Diagram__Wrapper {
    width: max-content;
    margin-left: auto;
    padding-left: -10vw;
    margin-right: auto;
    position: relative;
}

.whatIsStatbim__Diagram__Wrapper__Line {
    left: -175px;
    position: absolute;
    width: 346px;
    height: 10px;
    background: #37A3E0;
}

.whatIsStatbim__Diagram__Wrapper__Name {
    left: -174px;
    width: 348px;
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: .2rem;
}

.whatIsStatbim__Diagram__General {
    height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.whatIsStatbim__Diagram__BlueCircle__arrows {
    width: 360px;
    height: 360px;
    position: absolute;
    top: -65px;
    left: -65px;
}

.whatIsStatbim__Diagram__General__BlackCircle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: #232422;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
}

.whatIsStatbim__Diagram__General__arrow-light {
    margin: auto 50px auto 60px;
    position: relative;
    width: max-content;
    height: 20px;
    display: flex;
}

.whatIsStatbim__Diagram__General__arrow-light__line {
    margin: auto;
    height: 3px;
    width: 150px;
    background-color: #7E8089;
}

.whatIsStatbim__Diagram__General__arrow-light__end {
    border: 10px solid transparent;
    border-left: 16px solid #7E8089;
}

.whatIsStatbim__Diagram__General__Center {
    margin-top: auto;
    margin-bottom: auto;
    height: max-content;
    position: relative;
}

.whatIsStatbim__Diagram__General__Center__BlueCircle {
    position: relative;
    width: 230px;
    height: 230px;
    border-radius: 115px;
    background: #37A3E0;
}

.whatIsStatbim__Diagram__General__Center__BlueCircle__Text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 40px;
    position: absolute;
    top: 85px;
    left: 35px;
}

.whatIsStatbim__Diagram__Underline {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10vw;
    width: 500px;
    height: 22px;
    border-radius: 0 0 15px 15px;
    border: 2px solid #7E8089;
    border-top-width: 0;
}

.whatIsStatbim__Diagram__Underline__Text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    margin-top: 1vh;
}

.whatIsStatbim__Diagram__plus__wrapper {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    position: absolute;
    display: flex;
    width: 300px;
    z-index: 300;
    /* line-height: 50px; */
}

.plus-wrapper-text {
    width: 200px;
}

.plus-wrapper-circle {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #232422;
}

.plus-wrapper-circle-plus {
    width: max-content;
    height: max-content;
    margin: auto;
    padding-top: 12px;
}
