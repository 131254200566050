.serviceDevelopment__Diagram {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
}

.serviceDevelopment__Diagram__Center {
    position: relative;
    width: 400px;
    height: 400px;
}

.serviceDevelopment__Diagram__Center__Circle {
    position: absolute;
    top: 90px;
    left: 90px;
}


.serviceDevelopment__Diagram__BlueCircle__arrows {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
}

.serviceDevelopment__Diagram__BlueCircle {
    position: relative;
    width: 230px;
    height: 230px;
    border-radius: 115px;
    background: #37A3E0;
}

.serviceDevelopment__Diagram__BlueCircle__Text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 40px;
    position: absolute;
    top: 85px;
    left: 35px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks {
    display: flex;
    flex-direction: row;
    gap: 110px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Wrapper {
    width: max-content;
    position: absolute;
    top: 400px;
    left: -210px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__left {
    position: absolute;
    left: 100px;
    top: -50px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__arrow__left {
    position: relative;
    width: 170px;
    height: 2px;
    background-color: #7E8089;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__arrow__left::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #7E8089;
    border: 1px solid #7E8089;
    height: 30px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__right {
    position: absolute;
    right: 100px;
    top: -50px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__arrow__right {
    position: relative;
    width: 170px;
    height: 2px;
    background-color: #7E8089;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__arrow__right::before {
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #7E8089;
    border: 1px solid #7E8089;
    height: 30px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__center {
    position: absolute;
    right: 200px;
    top: -50px;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__arrow__center {
    position: relative;
    background-color: #7E8089;
}

.serviceDevelopment__Diagram__Center__BottomBlocks__Arrow__arrow__center::before {
    content: "";
    position: absolute;
    top: 55px;
    right: 210px;
    background-color: #7E8089;
    border: 1px solid #7E8089;
    height: 25px;
}

.serviceDevelopment__Diagram__Center__LeftBlocks__Wrapper {
    width: max-content;
    height: max-content;
    position: absolute;
    top: 135px;
    left: -350px;
}

.serviceDevelopment__Diagram__Center__LeftBlocks__Arrow {
    position: absolute;
    top: -25px;
    left: 100px;
}

.serviceDevelopment__Diagram__Center__LeftBlocks__Arrow__arrow {
    position: relative;
    width: 250px;
    height: 2px;
    background-color: #7E8089;
}

.serviceDevelopment__Diagram__Center__LeftBlocks__Arrow__arrow::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #7E8089;
    border: 1px solid #7E8089;
    height: 25px;
}


