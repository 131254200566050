.object__Wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    margin-top: 40px;

    background-color: rgba(179, 182, 186, 0.2784);
    width: 200px;
    height: 80px;
    border-radius: 14px;
}

.object__name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 22px;
    margin: auto auto 2px;
}

.object__icon {
    position: absolute;
    top: -30px;
    left: 70px;
}

.object__TextBlock__ObjectCircle__BlackCircle__circle {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #232422;
}

.object__icon__wrapper {
    position: relative;
    width: 60px;
    height: 60px;
}

.object__icon__svg {
    position: absolute;
    top: 7px;
    left: 7px;
}
