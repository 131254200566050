@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

/* FILE UPLOAD */

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 100px;
  border: 5px dashed rgb(117, 112, 112);
  padding: 2rem;
}


.list-item {
  display: flex;
  padding: 0;
  margin: 8px 0;
  min-width: 300px;
  font-size: 22px;
  background-color: var(--black-border);
  cursor: grab;
  border-radius: 10px;
}

.list-item > h3 {
  padding: 10px;
  margin: 0;
}

.list-item > span {
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  border-right: 2px solid white;
  padding: 10px 16px 10px 10px;
}

.next-position {
  border: 2px solid #34d44f;
  opacity: 0.2;
}

