@media (max-width: 1024px) {
    .info_wrapper {
        height: 275px;
        border-radius: 25px;
        background: #2312;
        padding: 20px;
        margin: 3vh auto;
    }

    .plot_wrapper {
        height: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 25px;
    }

    .plot_hist {
        width: 320px;
    }
}

@media (min-width: 1024px) {
    .info_wrapper {
        width: 80%;
        height: 275px;
        border-radius: 25px;
        background: #2312;
        padding: 20px;
        margin: 3vh auto;
    }

    .plot_wrapper {
        border-radius: 25px;
        width: 670px;
        margin: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /* display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr)); */
    }
}
