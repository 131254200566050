.bigBubble__Wrapper {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 300px;
    border-radius: 150px;
}

.bigBubble__number {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin: 20px auto 30px;
    font-size: 24px;
    line-height: 22px;

    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: #232422;
    color: white;
    text-align: center;
    vertical-align: center;

}

.bigBubble__header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 17px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 3px;
    text-align: center;
}

.bigBubble__body {
    width: 240px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin: auto auto 40px;
}
