.form_wrapper {
    display: flex;
    flex-direction: row;
}



@media(min-width: 769px){
	.form_radio_wrapper {
		margin-left: auto;
		margin-right: auto;
		width: 65vw;
		height: 100px;
		display: flex;
		flex-direction: column;
	}
}

@media(max-width: 769px){
	.form_radio_wrapper {
		margin-left: auto;
		margin-right: auto;
		width: 320px;
		height: 100px;
		display: flex;
		flex-direction: column;
	}
}

.form_radio_group {
	display: inline-block;
	overflow: hidden;
}
.form_radio_group-item {
	display: inline-block;
	float: left;    
}
.form_radio_group input[type=radio] {
	display: none;
}
.form_radio_group label {
	display: inline-block;
	cursor: pointer;
	padding: 0px 15px;
	line-height: 34px;
	border: 1px solid #999;
	border-right: none;
	user-select: none;
}
 
.form_radio_group .form_radio_group-item:first-child label {
	border-radius: 6px 0 0 6px;
}
.form_radio_group .form_radio_group-item:last-child label {
	border-radius: 0 6px 6px 0;
	border-right: 1px solid #999;
}
 
/* Checked */
.form_radio_group input[type=radio]:checked + label {
	background: #ffe0a6;
}
 
/* Hover */
.form_radio_group label:hover {
	color: #666;
}
 
/* Disabled */
.form_radio_group input[type=radio]:disabled + label {
	background: #efefef;
	color: #666;
}


.form__radio__wrapper {
	margin-top: 3vh;
	height: 33vh;
	border-radius: 25px;
	background-color: #ffffff;
	box-shadow: 2px 4px 5px 2px rgba(0, 0, 0, 0.3);
}

.form__radio__wrapper__group {
	display: flex;
	flex-direction: row;
	padding: 30px;
}

.group__item {
	width: 140px;
	height: 35px;
	padding: 5px;
	text-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: #bae0f5;
	margin-right: 10px;
	cursor: pointer;
}

.analyses__button {
	display: flex;
	cursor: pointer;
	width: 150px;
	height: 50px;
	border-radius: 20px;
	background-color: #34d44f;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.analyses__button__span {
	margin-left: auto;
}

.analyses__button__icon {
	margin-right: auto;
}


