.files_list {
    width: 200px;
    margin: auto;
}

.files_list_item {
    font-size: 28px;
    width: 250px;
}

.uploads {
    height: 60vh;
    margin: auto;
    width: 200px;
    display: flex;
}

.upload_continue {
    margin-top: auto;
    margin-bottom: auto;
}

.actions {
    display: flex;
    justify-content: space-between;
    width: 200px;
}

.cancel {
    color: darkred;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
}

.upload {
    color: darkgreen;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
}
