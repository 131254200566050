.simple-navbar-wrapper {
    display: flex;
    width: 80vw;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 22px;
    z-index: 9999;
    margin: auto;
}

.simpe-navbar {
    position: sticky;
    top: 0;
    z-index: 9998;
    padding-top: 10px;
    padding-bottom: 10px;
    height: max-content;
    background-color: #EFF0EF;
}

.simple-navbar-collection {
    display: flex;
    gap: 30px;
}

.simple-navbar-collection-item {
    margin-top: auto;
    margin-bottom: auto;
}

.simple-navbar-logotip {
    height: 75px;
    position: static;
    top: -100px;
}

.simple-navbar-collection-item-wrapper {
    position: relative;
    cursor: pointer;
}

.simple-navbar-collection-line {
    left: -5px;
    top: 3px;
    position: absolute;
    height: 10px;
    background: #37A3E0;
    z-index: 100;
}

.simple-navbar-collection-name {
    position: relative;
    z-index: 9998;
}

.simple-navbar-logo {
    letter-spacing: 3px;
    position: relative;
}

.simple-navbar-line {
    left: -5px;
    top: 3px;
    position: absolute;
    width: 132px;
    height: 10px;
    background: #37A3E0;
}

.simple-navbar-name {
    left: 0px;
    width: max-content;
    position: absolute;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: .2rem;
}
