.finalSteps-wrapper {
    position: relative;
    display: flex;
    height: 100vh;
    margin-bottom: 100px;
}

.finalSteps-stones {
    position: absolute;
    height: 740px;
    left: -10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.finalSteps-numbers {
    margin: auto 55px 200px calc(10vw);
}





.finalSteps-list-wrapper {
    height: 100vh;
    display: flex;
}

.finalSteps-list {
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
}



.finalSteps-contacts {
    margin-right: 0;
}
