@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

.welcomePage__Wrapper {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    transition: width 0.5s ease;
}

.welcomePage__Header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 48px;
}

.welcomePage-contacts {
}

.element-animation {
    opacity: 0;
    transform: translateY(40px);
}

.element-animation.element-show {
    opacity: 1;
    transition: all 1.5s;
    transform: translateY(0%);
}
