.mobile_wrapper {
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    margin-top: 35px;
    padding: 10px;
    justify-content: space-around;
}

.mobile_name {
    margin-right: 15px;
}

.mobile_icon {
    margin-top: auto;
    margin-bottom: auto;
}
