.block-number-wrapper {
    position: relative;
    display: flex;
    width: 125px;
    height: 125px;
    border-radius: 20px;
    transform: rotate(45deg);
    background-color: rgba(179, 182, 186, 0.302);
}

.block-number-main {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    z-index: 200;
    margin: auto;
}

.block-number {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 46px;
    margin: auto;
    transform: rotate(-45deg);
}

.block-number-dot-stone {
    position: absolute;
    top: 135px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: rgba(126, 128, 137, 1);
}