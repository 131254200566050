.InsideWrapper {
    margin-left: auto;
    margin-right: auto;
}

.table-name {
    width: max-content;
    height: 70px;
    margin: auto;
    padding-bottom: 10px;
    font-size: 40px;
}