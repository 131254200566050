@media (min-width: 1000px) {
    .plusAndMinus__Wrapper {
        height: 100vh;
        width: 80vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 1000px) {
    .plusAndMinus__Wrapper {
        height: 100vh;
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media (max-width: 1000px) {
    .plusAndMinus__list {
        height: 500px;
        max-height: 1000px;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media (min-width: 1000px) {
    .plusAndMinus__list {
        height: 500px;
        max-height: 1000px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.plusAndMinus__list__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: max-content;
    height: 34px;
}

.plusAndMinus__list__wrapper__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 2px;
    margin-top: auto;
    margin-bottom: auto;
    width: 270px;
    text-align: right;
    margin-right: 7px;
}

.plusAndMinus__list__wrapper__icon {
    background-color: rgba(55, 163, 224, 1);
    width: 34px;
    height: 34px;
    border-radius: 17px;
    margin-top: auto;
    margin-bottom: auto;
}

.wrap {
    position: relative;
    margin-top: 15px;
    margin-left: 8px;
}

.check-sign {
    position: absolute;
    background: rgba(35, 36, 34, 1);
}

#check-part-1 {
    width: 10px;
    height: 3px;
    transform:rotate(45deg);
    border-radius: 5px;
}

#check-part-2 {
    width: 15px;
    height: 3px;
    transform: rotate(-45deg);
    left: 5px;
    top: -1px;
    border-radius: 9px;
}


.plusAndMinus__list__wrapper__text__reverse {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 2px;
    margin-top: auto;
    margin-bottom: auto;
    width: 270px;
    text-align: left;
    margin-left: 7px;
}

.plusAndMinus__list__reverse {
    height: 80vh;
    max-height: 1000px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
}


.plusAndMinus__list__wrapper__icon__reverse {
    background-color: rgba(147, 150, 156, 1);
    width: 34px;
    height: 34px;
    border-radius: 17px;
    margin-top: auto;
    margin-bottom: auto;
}

.wrap__reverse {
    position: relative;
    margin-top: 15px;
    margin-left: 8px;
}

.check-sign-reverse {
    position: absolute;
    background: rgba(239, 240, 239, 1);
    padding-left: 3px;
}

#check-part-1-reverse {
    width: 15px;
    height: 3px;
    transform:rotate(45deg);
    border-radius: 5px;
    left: 2px;
}

#check-part-2-reverse {
    width: 15px;
    height: 3px;
    transform: rotate(-45deg);
    left: 2px;
    top: 0px;
    border-radius: 5px;
}

.plusAndMinus__list__wrapper__reverse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: max-content;
    height: 34px;
    margin-top: auto;
    margin-bottom: auto;
}
