@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
.wrapper__login {
    margin: auto;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    width: 100%;
    max-width: 700px;
}
.container__login{
    max-width: 700px;
    width: 100%;
    background-color: #fff;
    padding: 25px 30px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.container__login .title__login{
    font-size: 25px;
    font-weight: 500;
    position: relative;
}
.container__login .title__login::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background: linear-gradient(135deg, var(--light-blue), var(--blue));
}
.content__login form .user-details__login{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
}
form .user-details__login .input-box__login{
    margin-bottom: 15px;
    width: calc(100% / 2 - 20px);
}
form .input-box__login span.details__login{
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.user-details__login .input-box__login input{
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.user-details__login .input-box__login input:focus,
.user-details__login .input-box__login input:valid{
    border-color: var(--blue);
}

form .category__login{
    display: flex;
    width: 80%;
    margin: 14px 0 ;
    justify-content: space-between;
}
form .category__login label{
    display: flex;
    align-items: center;
    cursor: pointer;
}
form .category__login label .dot{
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    background: #d9d9d9;
    border: 5px solid transparent;
    transition: all 0.3s ease;
}

.checkbox {
    font-size: 16px;
    line-height: 20px;
    height: 45px;
    padding-left: 15px;
}

form .button__login{
    height: 45px;
    margin: 35px 0
}
form .button__login input{
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, var(--light-blue), var(--blue));
}
form .button__login input:hover{
    /* transform: scale(0.99); */
    background: linear-gradient(-135deg, var(--light-blue), #f8f8d9);
}
@media(max-width: 584px){
    .container__login{
        max-width: 100%;
    }
    form .user-details__login .input-box__login{
        margin-bottom: 15px;
        width: 100%;
    }
    form .category__login{
        width: 100%;
    }
    .content__login form .user-details__login{
        max-height: 300px;
        overflow-y: scroll;
    }
    .user-details__login::-webkit-scrollbar{
        width: 5px;
    }
}
@media(max-width: 459px){
    .container__login .content__login .category__login{
        flex-direction: column;
    }
}
