.whyConsumerNeed__Wrapper {

}

.whyConsumerNeed__HeaderName {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 48px;
}

.whyConsumerNeed__TextBlock {
    display: flex;
}

.whyConsumerNeed__TextBlock__ObjectCircle {
    position: relative;
}

.whyConsumerNeed__TextBlock__BlackCircle {
    position: absolute;
    top: 0;
    left: 0;
}

.whyConsumerNeed__TextBlock__ObjectCircle__Text {
    position: absolute;
    top: 21px;
    left: 21px;
}

.whyConsumerNeed__TextBlock__BodyText {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 26px;
    width: 80%;
    margin-left: 1vw;
    line-height: 42px;
}

.whyConsumerNeed__Bubbles {
    display: flex;
    flex-direction: column;
}

.whyConsumerNeed__Bubbles__Forward {
    display: flex;
    gap: 25px;
}

.whyConsumerNeed__Bubbles__Reverse {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
}
