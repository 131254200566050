.exampleResults__HeaderName {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 48px;
}

.exampleResults__Content {
    height: 750px;
}

.exampleResults-content-bars {
    display: flex;
    flex-direction: row;
}

.exampleResults-content-text {
    width: 500px;
    height: 150px;
    border-radius: 20px;
    background-color: rgb(212, 215, 218);
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
}

.exampleResults-content-text-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    height: 50;
    margin: auto;
}

.exampleResults-content-text-item-name {

}

.exampleResults-content-text-item-result {

}