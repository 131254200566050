.mini-doughnut-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
}

.mini-doughnut {
    border: 45px solid #C0E6FB;
    border-radius: 125px;
    height: 250px;
    width:250px;
}

.mini-nice {
    position: absolute;
    background-color: rgba(55, 163, 224, 1);
    width: 70px;
    height: 70px;
    top: 95px;
    left: -13px;
    border-radius: 75px;
    margin-top: auto;
    margin-bottom: auto;
}

.mini-wrap-nice {
    position: relative;
    margin-top: 35px;
    margin-left: 65px;
}

.mini-check-sign-nice {
    position: absolute;
    background: rgba(206, 224, 248, 1);
}

#mini-check-part-1-nice {
    width: 27px;
    height: 7px;
    transform:rotate(45deg);
    border-radius: 20px;
    left: -60px;
    top: 8px;
}

#mini-check-part-2-nice {
    width: 40px;
    height: 7px;
    transform: rotate(-45deg);
    left: -47px;
    top: 3px;
    border-radius: 20px;
}

.mini-bad {
    position: absolute;
    background-color: rgba(147, 150, 156, 1);
    width: 70px;
    height: 70px;
    top: 95px;
    left: 192px;
    border-radius: 35px;
    margin-top: auto;
    margin-bottom: auto;
}

.mini-wrap-bad {
    position: relative;
    margin-top: 40px;
    margin-left: 50px;
}

.mini-check-sign-bad {
    position: absolute;
    background: rgba(179, 182, 186, 1);
}

#mini-check-part-1-bad {
    width: 45px;
    height: 9px;
    transform:rotate(45deg);
    border-radius: 20px;
    left: -35px;
    top: -5px;
}

#mini-check-part-2-bad {
    width: 45px;
    height: 9px;
    transform: rotate(-45deg);
    left: -35px;
    top: -5px;
    border-radius: 20px;
}
