.project {
    max-width: 370px;
    background-color: var(--project-card-bg);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    min-width: 370px;
    min-height: 160px;
}

.project__img {
    border-radius: 10px;
}

.project__title {
    padding: 15px 20px 25px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: var(--project-card-text);
}
