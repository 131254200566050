.doughnut__wrapper {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
}

.doughnut {
    border: 95px solid #C0E6FB;
    border-radius: 250px;
    height:500px;
    width:500px;
}

.nice {
    position: absolute;
    background-color: rgba(55, 163, 224, 1);
    width: 150px;
    height: 150px;
    top: 180px;
    left: -25px;
    border-radius: 75px;
    margin-top: auto;
    margin-bottom: auto;
}

.wrap-nice {
    position: relative;
    margin-top: 75px;
    margin-left: 65px;
}

.check-sign-nice {
    position: absolute;
    background: rgba(206, 224, 248, 1);
}

#check-part-1-nice {
    width: 70px;
    height: 20px;
    transform:rotate(45deg);
    border-radius: 20px;
    left: -60px;
    top: 8px;
}

#check-part-2-nice {
    width: 95px;
    height: 20px;
    transform: rotate(-45deg);
    left: -27px;
    top: -1px;
    border-radius: 20px;
}

.bad {
    position: absolute;
    background-color: rgba(147, 150, 156, 1);
    width: 150px;
    height: 150px;
    top: 180px;
    left: 375px;
    border-radius: 75px;
    margin-top: auto;
    margin-bottom: auto;
}

.wrap-bad {
    position: relative;
    margin-top: 75px;
    margin-left: 65px;
}

.check-sign-bad {
    position: absolute;
    background: rgba(179, 182, 186, 1);
}

#check-part-1-bad {
    width: 95px;
    height: 20px;
    transform:rotate(45deg);
    border-radius: 20px;
    left: -35px;
    top: -5px;
}

#check-part-2-bad {
    width: 95px;
    height: 20px;
    transform: rotate(-45deg);
    left: -35px;
    top: -5px;
    border-radius: 20px;
}
