.mini-bubble-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-color: rgba(78, 187, 248, 1);
    display: flex;
}

.mini-bubble-text {
    width: 100px;
    height: max-content;
    border-radius: 50px;
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
}