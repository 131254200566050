.contacts {
    width: 500px;
    height: 100vh;
    display: flex;
}

.contacts-wrapper {
    position: absolute;
    right: calc(-430px);
    position: fixed;
    top: 0px;
    z-index: 9999;
    background-color: #fff;
    transition: 1s;
    transform: translateX(0%);
}

.contacts-wrapper_active {
    transform: translateX(-400px);
}

.contacts-form {
    width: 450px;
    display: flex;
    flex-direction: column;
}

.contacts-form-inputs {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.contacts-form-input {
    width: 300px;
    height: 30px;
    border: 1px solid black;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 4px 2px -2px gray;
    margin-bottom: 3vh;
}

.contacts-form-check {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    line-height: 30px;
}

.contacts-form-button-accept {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 7px;
    background-color: rgba(55, 163, 224, 1);
    border: 1px solid black;
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

.contacts-line {
    width: 80px;
    height: 100vh;
    background-color: rgba(55, 163, 224, 1);
    cursor: pointer;
}

.contacts-form-name {
    margin-top: 5vh;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}