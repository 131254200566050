@media (min-width: 1500px) {
    .whyPlantNeed__Bubbles {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 650px;
        margin: auto;
    }
    
    .whyPlantNeed__Bubbles__Reverse {
        position: relative;
        /* top: 310px;
        left: 700px; */
    }
    
    .whyPlantNeed__Bubbles__Forward {
        display: flex;
        gap: 10px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_5 {
        position: absolute;
        left: 650px;
        top: 15px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_4 {
        position: absolute;
        top: -200px;
        left: 950px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-1 {
        position: absolute;
        top: -355px;
        left: 950px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-2 {
        position: absolute;
        top: -325px;
        left: 1150px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-3 {
        position: absolute;
        top: 85px;
        left: 1150px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-4 {
        position: absolute;
        top: 120px;
        left: 985px;
    }
}

@media (max-width: 1500px) {
    .whyPlantNeed__Bubbles {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 600px;
        margin: auto;
    }
    
    .whyPlantNeed__Bubbles__Reverse {
        position: relative;
    }
    
    .whyPlantNeed__Bubbles__Forward {
        display: flex;
        gap: 10px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_5 {
        position: absolute;
        left: 350px;
        top: 15px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_4 {
        position: absolute;
        top: 0px;
        left: 850px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-1 {
        position: absolute;
        top: -335px;
        left: 930px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-2 {
        position: absolute;
        top: -200px;
        left: 1000px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-3 {
        position: absolute;
        top: 95px;
        left: 670px;
    }
    
    .whyPlantNeed__Bubbles__Reverse_mini-4 {
        position: absolute;
        top: 10px;
        left: 200px;
    }
}

